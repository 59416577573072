import { createContext, Dispatch } from "react";

export enum CardsActionType {
	addSelected = "ADD_SELECTED",
	removeSelected = "REMOVE_SELECTED",
	selectAll = "SELECT_ALL",
	setCardsArray = "SET_CARDS_ARRAY",
	insertCardsArray = "INSERT_CARDS_ARRAY",
	setSearchValue = "SET_SEARCH_VALUE",
	addFilteredChains = "ADD_FILTERED_CHAINS",
	removeFilteredChains = "REMOVE_FILTERED_CHAINS",
}

export type CardsState = {
	selectedCards: any[];
	cardsArray: any[];
	searchValue: string;
	filteredChains: any[];
};

export const cardsInitialState = {
	selectedCards: [],
	cardsArray: [],
	searchValue: "",
	filteredChains: [],
};

export const cardsReducer = (state: CardsState, action: any) => {
	switch (action.type) {
		case CardsActionType.addSelected:
			return {
				...state,
				selectedCards: [
					...state.selectedCards,
					{
						chainId: action.payload.chainId,
						contract: action.payload.contract,
						tokenId: action.payload.tokenId,
						image: action.payload.image,
						name: action.payload.name,
						version: action.payload.version,
						addresses: action.payload.addresses,
					},
				],
			};
		case CardsActionType.removeSelected:
			const index = state.selectedCards.findIndex(
				(card) =>
					card.chainId === action.payload.chainId &&
					card.contract === action.payload.contract &&
					card.tokenId === action.payload.tokenId
			);
			if (index !== -1) {
				state.selectedCards.splice(index, 1);
			}
			return state;
		case CardsActionType.selectAll:
			const cards = state.cardsArray.filter(
				(item) => item.chainId === action.payload
			);
			if (
				state.selectedCards.length > 0 &&
				state.selectedCards[0].chainId === action.payload &&
				state.selectedCards.length === cards.length
			) {
				return {
					...state,
					selectedCards: [],
				};
			} else {
				return {
					...state,
					selectedCards: cards.map((item) => ({
						chainId: item.chainId,
						contract: item.info.contractAddress,
						tokenId: item.info.tokenId,
						image: item.info.metadata.image,
						name: item.info.metadata.name,
					})),
				};
			}
		case CardsActionType.setCardsArray:
			return {
				...state,
				cardsArray: action.payload,
			};
		case CardsActionType.insertCardsArray:
			return {
				...state,
				cardsArray: [...state.cardsArray, action.payload],
			};
		case CardsActionType.setSearchValue:
			return {
				...state,
				searchValue: action.payload,
			};
		case CardsActionType.addFilteredChains:
			const filteredChainIndex = state.filteredChains.indexOf(action.payload);
			if (filteredChainIndex === -1)
				return {
					...state,
					filteredChains: [...state.filteredChains, action.payload],
				};
			return state;
		case CardsActionType.removeFilteredChains:
			const filteredChainIndexToRemove = state.filteredChains.indexOf(
				action.payload
			);
			if (filteredChainIndexToRemove !== -1)
				return {
					...state,
					filteredChains: state.filteredChains.splice(
						filteredChainIndexToRemove
					),
				};
			return state;
		default:
			return state;
	}
};

export const CardsContext = createContext<{
	cardsState: CardsState;
	cardsDispatch: Dispatch<any>;
}>({
	cardsState: cardsInitialState,
	cardsDispatch: () => null,
});
