import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import NavBar from "../../components/NavBar";
import WalletInfo from "../../components/WalletInfo";
import ChainNFT from "../../components/ChainNFT";
import { CHAIN_TYPES } from "../../constants";
import { Footer } from "../../components/Footer";
import { ToolBar } from "../../components/ToolBar";
import { Loader } from "../../components/Loader";
import { CardsContext, WalletContext } from "../../context";

const Wrapper = styled.div`
	position: relative;
	min-height: calc(100vh - 104px);

	.backgroundWrapper {
		background-image: url("/assets/imgs/website_background.png");
		background-size: 100% auto;

		padding: 100px 0 50px 0;

		&:before {
			background-image: url("/assets/imgs/white-area-top.svg");
			padding: 14%;
			top: -27.7vw;
			position: absolute;
			width: 100%;
			display: block;
			content: "";
			background-repeat: none;
			background-position: center;
			background-size: cover;
			pointer-events: none;
		}
	}
`;

const ContentWrapper = styled.div`
	width: 80%;
	margin: auto;
	margin-top: 150px;
`;

const NFT_INFO = [
	{
		img: "1.png",
		name: `Material World #19048`,
	},
	{
		img: "2.png",
		name: `Zeuz #895`,
	},
	{
		img: "3.png",
		name: `Zeuz #895`,
	},
	{
		img: "4.png",
		name: `Zeuz #895`,
	},
	{
		img: "5.png",
		name: `Zeuz #895`,
	},
	{
		img: "6.png",
		name: `Zeuz #895`,
	},
];

export const Gallery = () => {
	const { address: searchAddress } = useParams();
	const [block, setBlock] = useState(0);

	// const [quantityToMint, setQuantityToMint] = useState(1); // Assuming you want to mint 1 NFT, you can adjust this

	const { walletState } = useContext(WalletContext);
	const { cardsState } = useContext(CardsContext);

	useEffect(() => {
		if (block !== 0) toast.info("Updating NFT data..");
	}, [block]);

	useEffect(() => {
		if (!walletState.isConnected)
			toast.warning("Connect your wallet to see your NFTs");
	}, [walletState]);

	const isActiveChain = (chainId: string) => {
		if (!cardsState.filteredChains.length) return true;

		return cardsState.filteredChains.indexOf(chainId) !== -1;
	};

	return (
		<>
			<Wrapper className="relative w-screen">
				<NavBar />

				<div className="container m-auto">
					<ContentWrapper>
						<WalletInfo />

						{walletState.isConnected || searchAddress ? (
							<>
								<ToolBar block={block} setBlock={setBlock} />
								<div className="my-4 text-center">
									<p className="text-white text-2xl">
										The traverse process can take up to 30 minutes.
									</p>
									<p className="text-white text-2xl mt-4">
										LayerZero's traverse fee is calculated based on destination
										chain gas and paid in source chain token.
									</p>
									<div className="text-center mt-4">
										<p className="text-white text-2xl">
											If you don't have enough of the native token you need for
											the traverse fee, you can use a token swap site, such as:{" "}
											<a
												className="text-[#FC2947]"
												href="https://stargate.finance/"
												rel="noopener noreferrer"
												target="_blank"
											>
												Stargate finance
											</a>
											,{" "}
											<a
												className="text-[#FC2947]"
												href="https://simpleswap.io/"
												rel="noopener noreferrer"
												target="_blank"
											>
												Simple Swap
											</a>
											,{" "}
											<a
												className="text-[#FC2947]"
												href="https://www.synapseprotocol.com/"
												rel="noopener noreferrer"
												target="_blank"
											>
												Synapse Protocol
											</a>
											,{" "}
											<a
												className="text-[#FC2947]"
												href="https://omoswap.xyz/"
												rel="noopener noreferrer"
												target="_blank"
											>
												OmoSwap
											</a>
											,{" "}
											<a
												className="text-[#FC2947]"
												href="https://cbridge.celer.network/"
												rel="noopener noreferrer"
												target="_blank"
											>
												cBridge.
											</a>
										</p>
										<p className="text-white text-lg mt-4">
											*TraverseMyONFT has no affiliation with any of the linked
											swap sites, nor do we receive referral fees
										</p>
									</div>
								</div>
								{Object.keys(CHAIN_TYPES).map((key: string, index: number) => (
									<ChainNFT
										key={`chainnfts${index}`}
										chainType={CHAIN_TYPES[key as keyof typeof CHAIN_TYPES]}
										walletAddress={
											searchAddress ? searchAddress : walletState.address
										}
										cards={NFT_INFO.slice(0, 5)}
										active={isActiveChain(
											CHAIN_TYPES[key as keyof typeof CHAIN_TYPES]
										)}
										block={block}
										setBlock={setBlock}
									/>
								))}
							</>
						) : (
							<Loader />
						)}
					</ContentWrapper>
				</div>

				<div className="flex flex-col justify-center items-center mt-5">
					<h1 className="text-white text-3xl">
						Don't see an ONFT you have in your wallet?{" "}
						<a className="text-[#E11075]" href="/request">
							{" "}
							Click here{" "}
						</a>{" "}
						to fill out the request form for us to add it!
					</h1>
				</div>
			</Wrapper>

			<Footer />
		</>
	);
};

export default Gallery;
