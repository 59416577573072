export const CHAIN_TYPES = {
	// Ethereum: "0x1",
	Base: "0x2105",
	Polygon: "0x89",
	BSC: "0x38",
	Optimism: "0xa",
	Avalanche: "0xa86a",
	MoonBeam: "0x504",
	Linea: "0xe708",
	Scroll: "0x82750",
	// OpBNB: "0xcc",
	// Blast: "0x13e31",
};

export const CHAIN_COLORS = {
	// [CHAIN_TYPES.Ethereum]: "#627EEA",
	[CHAIN_TYPES.Base]: "#39a5a3",
	[CHAIN_TYPES.Polygon]: "#9849FC",
	[CHAIN_TYPES.BSC]: "#F0B90B",
	[CHAIN_TYPES.Optimism]: "#ff0000",
	[CHAIN_TYPES.Avalanche]: "#FC2947",
	[CHAIN_TYPES.MoonBeam]: "#4ab6b4",
	[CHAIN_TYPES.Linea]: "#4ab6b4",
	[CHAIN_TYPES.Scroll]: "#4ab6b4",
	// [CHAIN_TYPES.OpBNB]: "#4ab6b4",
	// [CHAIN_TYPES.Blast]: "#4ab6b4",
};

export const CHAIN_NAMES = {
	// [CHAIN_TYPES.Ethereum]: "Ethereum",
	[CHAIN_TYPES.Base]: "Base",
	[CHAIN_TYPES.Polygon]: "Polygon",
	[CHAIN_TYPES.BSC]: "BNB Chain",
	[CHAIN_TYPES.Optimism]: "Optimism",
	[CHAIN_TYPES.Avalanche]: "Avalanche",
	[CHAIN_TYPES.MoonBeam]: "MoonBeam",
	[CHAIN_TYPES.Linea]: "Linea",
	[CHAIN_TYPES.Scroll]: "Scroll",
	// [CHAIN_TYPES.OpBNB]: "OpBNB",
	// [CHAIN_TYPES.Blast]: "Blast",
};

export const APIS_BASE_URL =
	"https://onft-traverse-backend-dev-ifiyhhjjgq-uc.a.run.app";
// export const APIS_BASE_URL = "http://localhost:3001";

export const TRAVERSAL_CONTRACT = {
	// [CHAIN_TYPES.Ethereum]: "Ethereum",
	[CHAIN_TYPES.Base]: "0xEddfBFa0f2319FA7Fd56f1cF307dDDbF4De97fAE",
	[CHAIN_TYPES.Polygon]: "0x487D3bF65FDD36Ed4F5E7966a4ac7Ae70396f9C9",
	[CHAIN_TYPES.BSC]: "0xF43Bd04022b2e23c60B84D523Ea8f4307cae600F",
	[CHAIN_TYPES.Optimism]: "0xA5c1b32f414d6c040dAb0efA4218c6E9c3FA962d",
	[CHAIN_TYPES.Avalanche]: "0x1E0D5E8ef44607ef490aeA58459C1c36653fC583",
	[CHAIN_TYPES.MoonBeam]: "0x281A8b2D88523c973dFe13217b09Ff193Ab4E9E2",
	[CHAIN_TYPES.Linea]: "0x003B24ddB34D7B2ACbce10BfB65E740D48A160f7",
	[CHAIN_TYPES.Scroll]: "0x003B24ddB34D7B2ACbce10BfB65E740D48A160f7",
	// [CHAIN_TYPES.OpBNB]: "0x003B24ddB34D7B2ACbce10BfB65E740D48A160f7",
	// [CHAIN_TYPES.Blast]: "0x003B24ddB34D7B2ACbce10BfB65E740D48A160f7",
};

export const CHAIN_TO_LZ = {
	// [CHAIN_TYPES.Ethereum]: "Ethereum",
	[CHAIN_TYPES.Base]: "184",
	[CHAIN_TYPES.Polygon]: "109",
	[CHAIN_TYPES.BSC]: "102",
	[CHAIN_TYPES.Optimism]: "111",
	[CHAIN_TYPES.Avalanche]: "106",
	[CHAIN_TYPES.MoonBeam]: "126",
	[CHAIN_TYPES.Linea]: "183",
	[CHAIN_TYPES.Scroll]: "214",
	// [CHAIN_TYPES.OpBNB]: "202",
	// [CHAIN_TYPES.Blast]: "243",
};

export const AggregatorAddress = {
	[CHAIN_TYPES.Base]: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
	[CHAIN_TYPES.Polygon]: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
	[CHAIN_TYPES.BSC]: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE",
	[CHAIN_TYPES.Optimism]: "0x13e3Ee699D1909E989722E753853AE30b17e08c5",
	[CHAIN_TYPES.Avalanche]: "0x0A77230d17318075983913bC2145DB16C7366156",
	[CHAIN_TYPES.MoonBeam]: "0x4497B606be93e773bbA5eaCFCb2ac5E2214220Eb",
};
