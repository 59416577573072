import { CHAIN_TYPES } from "../../../constants";

import AvalancheSVG from "./Avalanche.svg";
import BaseSVG from "./Base.svg";
import BSCSVG from "./BSC.svg";
import EthereumSVG from "./Ethereum.svg";
import FantomSVG from "./Fantom.svg";
import LineaSVG from "./Linea.svg";
import MoonBeamSVG from "./MoonBeam.svg";
import OptimismSVG from "./Optimism.svg";
import PolygonSVG from "./Polygon.svg";
import ScrollSVG from "./Scroll.svg";

import AvalanchePNG from "./Avalanche.png";
import BasePNG from "./Base.png";
import BSCPNG from "./BSC.png";
import EthereumPNG from "./Ethereum.png";
import FantomPNG from "./Fantom.png";
import LineaPNG from "./Linea.png";
import MoonBeamPNG from "./MoonBeam.png";
import OptimismPNG from "./Optimism.png";
import PolygonPNG from "./Polygon.png";
import ScrollPNG from "./Scroll.png";

export const CHAIN_SVG_ICON_BY_ID = {
	[CHAIN_TYPES.Avalanche]: AvalancheSVG,
	[CHAIN_TYPES.Base]: BaseSVG,
	[CHAIN_TYPES.BSC]: BSCSVG,
	[CHAIN_TYPES.Linea]: LineaSVG,
	[CHAIN_TYPES.MoonBeam]: MoonBeamSVG,
	[CHAIN_TYPES.Optimism]: OptimismSVG,
	[CHAIN_TYPES.Polygon]: PolygonSVG,
	[CHAIN_TYPES.Scroll]: ScrollSVG,
};
export const CHAIN_PNG_ICON_BY_ID = {
	[CHAIN_TYPES.Avalanche]: AvalanchePNG,
	[CHAIN_TYPES.Base]: BasePNG,
	[CHAIN_TYPES.BSC]: BSCPNG,
	[CHAIN_TYPES.Linea]: LineaPNG,
	[CHAIN_TYPES.MoonBeam]: MoonBeamPNG,
	[CHAIN_TYPES.Optimism]: OptimismPNG,
	[CHAIN_TYPES.Polygon]: PolygonPNG,
	[CHAIN_TYPES.Scroll]: ScrollPNG,
};

export const CHAIN_SVG_ICON_BY_NAME = {
	Avalanche: AvalancheSVG,
	Base: BaseSVG,
	BSC: BSCSVG,
	Ethereum: EthereumSVG,
	Fantom: FantomSVG,
	Linea: LineaSVG,
	MoonBeam: MoonBeamSVG,
	Optimism: OptimismSVG,
	Polygon: PolygonSVG,
	Scroll: ScrollSVG,
};
export const CHAIN_PNG_ICON_BY_NAME = {
	Avalanche: AvalanchePNG,
	Base: BasePNG,
	BSC: BSCPNG,
	Ethereum: EthereumPNG,
	Fantom: FantomPNG,
	Linea: LineaPNG,
	MoonBeam: MoonBeamPNG,
	Optimism: OptimismPNG,
	Polygon: PolygonPNG,
	Scroll: ScrollPNG,
};
