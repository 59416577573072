import { useState, useContext } from "react";

import { NormalButton } from "../../theme/components";
import { connectWallet, disConnectWallet } from "../../controllers/wallet";
import { WalletContext } from "../../context";

export const WalletButton = () => {
	const { walletState, walletDispatch } = useContext(WalletContext);
	const [connectedAddress, setConnectedAddress] = useState<string>("");

	const formatAddress = (address: string) => {
		return `${address.slice(0, 4)}...${address.slice(-4)}`;
	};

	const onClickWalletBtn = async () => {
		if (walletState.isConnected) {
			disConnectWallet(walletDispatch);
			setConnectedAddress("");
		} else {
			const address = await connectWallet(walletDispatch);
			if (address) {
				setConnectedAddress(address);
			}
		}
	};

	return (
		<NormalButton onClick={onClickWalletBtn}>
			{walletState.isConnected
				? `Wallet Connected ${formatAddress(connectedAddress)}`
				: "Connect Wallet"}
		</NormalButton>
	);
};
