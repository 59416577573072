import { createContext, Dispatch } from "react";

export enum WalletActionType {
	connect = "CONNECT",
	disconnect = "DISCONNECT",
	changeChain = "CHANGE_CHAIN",
	changeAccount = "CHANGE_ACCOUNT",
}

export type WalletState = {
	isConnected: boolean;
	provider: any;
	address: string;
	connectedChainId: number | string;
};

export const walletInitialState: WalletState = {
	isConnected: false,
	provider: {},
	address: "",
	connectedChainId: 0,
};

export const walletReducer = (state: WalletState, action: any) => {
	switch (action.type) {
		case WalletActionType.connect:
			return {
				...state,
				isConnected: true,
				provider: action.payload.provider,
				address: action.payload.address,
				connectedChainId: action.payload.connectedChainId,
			};
		case WalletActionType.disconnect:
			return {
				...state,
				isConnected: false,
				provider: {},
				address: "",
			};
		case WalletActionType.changeChain:
			return {
				...state,
				connectedChainId: action.payload.chainId,
			};
		case WalletActionType.changeAccount:
			return {
				...state,
				address: action.payload.address,
			};
		default:
			return state;
	}
};

export const WalletContext = createContext<{
	walletState: WalletState;
	walletDispatch: Dispatch<any>;
}>({
	walletState: walletInitialState,
	walletDispatch: () => null,
});
