import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Gallery from "./pages/Gallery";
import Request from "./pages/Request";
import FAQ from "./pages/FAQ";
import Mint from "./pages/Mint";
import Home from "./pages/Home";
import {
	WalletContext,
	walletReducer,
	walletInitialState,
	CardsContext,
	cardsReducer,
	cardsInitialState,
} from "./context";
import { useReducer } from "react";

function App() {
	const [walletState, walletDispatch] = useReducer(
		walletReducer,
		walletInitialState
	);
	const [cardsState, cardsDispatch] = useReducer(
		cardsReducer,
		cardsInitialState
	);

	return (
		<div className="App">
			<WalletContext.Provider value={{ walletState, walletDispatch }}>
				<CardsContext.Provider value={{ cardsState, cardsDispatch }}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/Gallery" element={<Gallery />} />
						<Route path="/address/:address" element={<Gallery />} />
						<Route path="/request" element={<Request />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/mint" element={<Mint />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
					<ToastContainer theme="colored" hideProgressBar={true} />
				</CardsContext.Provider>
			</WalletContext.Provider>
		</div>
	);
}

export default App;
