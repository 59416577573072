import styled from "styled-components";
import { CheckBox } from "../ChainNFT";
import React, { useContext, useState } from "react";
import { CHAIN_NAMES, CHAIN_TYPES } from "../../constants";

import { Menu, MenuHandler, Button, MenuList } from "@material-tailwind/react";
import { CardsActionType, CardsContext } from "../../context";

const ToolBarWrapper = styled.div`
	margin-top: 4rem;
	//   margin-bottom: 1.5rem;
	//   margin-left: 13%;
`;

const SearchInput = styled.div`
	height: 3rem;
	background: #1e1e1e;
	border: 1px solid #fffbf7;
	box-shadow: 0px 4px 22px -6px rgba(0, 0, 0, 0.38);
	border-radius: 31px;
	width: 100%;

	@media (max-width: 700px) {
		// left: -2.5rem;
	}

	//   left: -4rem;
	input {
		color: #fffbf7;
		outline: none;
		width: 100%;
		padding-left: 50px;
		padding-right: 1.5rem;
		font-size: 18px;
		background: transparent;
	}
`;

const SearchIcon = styled.img`
	position: absolute;
	transform: translate(0, -50%);
	top: 50%;
	left: 1rem;
`;

export const ToolBar = ({ block, setBlock }) => {
	const { cardsState, cardsDispatch } = useContext(CardsContext);
	const [menuOpen, setMenuOpen] = useState(false);

	const handleMenuOpen = () => {
		if (menuOpen) {
			setMenuOpen(false);
		} else {
			setMenuOpen(true);
		}
	};

	const [showSetting, setShowSetting] = useState(false);

	const onSearch = (ev: any) => {
		cardsDispatch({ type: CardsActionType, payload: ev.target.value });
	};

	const isFilteredChain = (chainId: any) => {
		const index = cardsState.filteredChains.indexOf(chainId);

		return index !== -1;
	};

	const onChangeCheckBox = (ev: any, chainId: any) => {
		const checked = ev.target.checked;

		if (checked)
			cardsDispatch({
				type: CardsActionType.addFilteredChains,
				payload: chainId,
			});
		else
			cardsDispatch({
				type: CardsActionType.removeFilteredChains,
				payload: chainId,
			});
	};

	return (
		<ToolBarWrapper className="flex gap-2 items-center md:w-[90%] m-auto">
			<SearchInput className="relative flex items-center col-span-8">
				<SearchIcon alt="pic" src="/assets/imgs/search_white.svg" />

				<input
					type="text"
					placeholder="Search"
					onChange={onSearch}
					value={cardsState.searchValue}
				/>
			</SearchInput>
			<div className="col-span-2 text-center">
				<Button
					className={`w-[40px] h-[40px] p-0 border border-white bg-black settingBtn ${
						showSetting ? "active" : ""
					}`}
					onClick={() => setBlock(block + 1)}
				>
					<img
						alt="pic"
						src="/assets/imgs/reload.svg"
						className="w-[36px] h-[20px]"
					/>
				</Button>
			</div>
			<div className="col-span-2 text-center">
				<Menu open={menuOpen} handler={handleMenuOpen}>
					<MenuHandler>
						<Button
							className={`w-[40px] h-[40px] p-[5px] border border-white bg-black settingBtn  ${
								showSetting ? "active" : ""
							}`}
							onClick={() => setShowSetting((prev) => !prev)}
						>
							<img
								alt="pic"
								src="/assets/imgs/setting.svg"
								className="w-[36px] h-[20px]"
							/>
						</Button>
					</MenuHandler>
					<div onClick={(e) => e.stopPropagation()}>
						<MenuList
							onClick={(e) => e.stopPropagation()}
							className={`bg-black ${showSetting ? "active" : ""}`}
						>
							{Object.keys(CHAIN_TYPES).map((key: string, index: number) => (
								<div
									key={`checkbox${index}`}
									onClick={(e) => e.stopPropagation()}
								>
									<CheckBox
										onClick={(e) => e.stopPropagation()}
										key={`checkbox${index}`}
										style={{ position: "relative" }}
									>
										<input
											onClick={(e) => e.stopPropagation()}
											type={"checkbox"}
											className="m-checkbox__input"
											id={`selectAllInput${index}`}
											value={"on"}
											checked={isFilteredChain(
												CHAIN_TYPES[key as keyof typeof CHAIN_TYPES]
											)}
											onChange={(ev) =>
												onChangeCheckBox(
													ev,
													CHAIN_TYPES[key as keyof typeof CHAIN_TYPES]
												)
											}
										/>
										<label
											onClick={(e) => e.stopPropagation()}
											className="m-checkbox__label"
											htmlFor={`selectAllInput${index}`}
										>
											{
												CHAIN_NAMES[
													CHAIN_TYPES[key as keyof typeof CHAIN_TYPES]
												]
											}
										</label>
									</CheckBox>
								</div>
							))}
						</MenuList>
					</div>
				</Menu>
			</div>
		</ToolBarWrapper>
	);
};
